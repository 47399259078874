.search-bar[hidden],
.search-bar *[hidden] {
  display: none;
}

.search-bar {
  border-bottom: 1px solid #e5e8ef;
  background: #fff;
  margin: 0 -15px;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  min-height: 46px;
}

.search-bar-input-wrapper {
  flex-grow: 1;
}

.search-bar-input {
  background: #f3f4f7;
  display: flex;
  align-items: center;
  border-radius: 3px;
  padding: 3px 8px;
  margin: 0;
}

.search-response {
  color: #151c29;
}

.search-bar-input .feather.search {
  margin-right: 5px;
}

.search-bar-input input {
  background: transparent;
  border: none;
  flex-grow: 1;
  color: #151c29;
  outline: none;
}

.search-bar .btn-link {
  box-shadow: none !important;
  background: transparent !important;
  color: #0075ff !important;
  text-decoration: none !important;
}

.highlighted-result {
  position: relative;
}

.highlighted-result::before {
  content: "";
  position: absolute;
  background: #fdf64d;
  border-radius: 3px;
  left: -1px;
  top: -2px;
  right: -1px;
  bottom: -2px;
  z-index: 1;
}

.highlighted-result .search-keyword {
  position: relative;
  z-index: 2;
  color: #151c29 !important;
}

.search-result {
  border-bottom: 2px dashed #dde3ed;
  margin-bottom: 10px;
  padding: 10px 0;
}

.search-result::after {
  content: "";
  clear: both;
}

.search-result:last-child {
  border-bottom: none;
}

.search-result .bubble-component:first-child .message-bubble-wrapper {
  margin-bottom: -25px;
}

.search-result .bubble-component:first-child .message-bubble-wrapper,
.search-result .bubble-component:first-child .avatar,
.search-result .keyword-found + .bubble-component .message-bubble-wrapper,
.search-result .keyword-found + .bubble-component .avatar {
  opacity: 0.6;
}

.search-result
  .bubble-component:first-child
  .message-bubble.me
  .message-bubble-wrapper,
.search-result .bubble-component:first-child .message-bubble.me .avatar,
.search-result
  .keyword-found
  + .bubble-component
  .message-bubble.me
  .message-bubble-wrapper,
.search-result .keyword-found + .bubble-component .message-bubble.me .avatar {
  opacity: 0.3;
}

.search-result .bubble-component:nth-child(3) .message-bubble-wrapper,
.search-result .bubble-component:nth-child(3) .avatar {
  margin-top: -25px;
}

.search-result .bubble-component:nth-child(3) .message-bubble-wrapper {
  margin-bottom: -20px;
}

.search-result .bubble-component:nth-child(3) .me > .message-bubble-wrapper {
  margin-bottom: 0;
}

.search-result .bubble-component:nth-child(1) .me .message-bubble-wrapper,
.search-result .bubble-component:nth-child(3) .me > .message-bubble-wrapper {
  opacity: 0.3;
}

.jump-to-conversation {
  display: block;
  clear: both;
  margin-top: 10px;
}

.keyword-found {
  opacity: 1;
  z-index: 2;
  position: relative;
  pointer-events: none;
}

.keyword-found .message-bubble-wrapper {
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.15);
  pointer-events: all;
}

.conversations-backdrop {
  background: rgba(30, 35, 40, 1);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  opacity: 0;
  transition: 300ms ease-in-out;
  visibility: hidden;
}

.conversations-backdrop.open {
  opacity: 0.5;
  visibility: visible;
}

.chat-header {
  display: block;
  position: relative;
}

.chat-header .more-vertical {
  position: absolute;
  right: 0;
  top: 0;
  padding: 16px;
  cursor: pointer;
}

.chat-header .dropdown-archived-conversations {
  position: absolute;
  right: -15px;
  top: 0;
  padding: 16px;
}

.chat-header .dropdown-archived-conversations .dropdown-menu {
  top: 83%;
  left: -180px;
}

.chat-header .filters {
  margin-left: 5px;
  display: inline-block;
  cursor: pointer;
}

.chat-file input {
  position: absolute;
  left: -99999px;
}

.chat-header *,
.chat-floating-button * {
  vertical-align: middle;
  display: inline-block;
}

.chat-header .dropdown-menu {
  display: none;
}

.chat-header .open > .dropdown-menu {
  display: block !important;
}

.chat-header .dropdown-menu li {
  display: block;
  position: relative;
}

.chat-header .dropdown-menu li a {
  display: block;
  position: relative;
}

.chat-header .message-square,
.chat-floating-button .bell {
  font-size: 22px;
  position: relative;
}

.messages-count {
  font-family: "Inter UI", sans-serif;
  font-size: 11px;
  position: absolute;
  font-weight: 600;
  top: 6.5px;
  left: 2px;
  right: 10px;
  text-align: center;
  display: block !important;
}

.practice-sidebar.collapsed .messages-count {
  top: 8.5px;
  left: 4px;
  right: 8px;
}

.chat-header .messages-count {
  right: 3px;
}

.has-message .messages-count {
  color: #fff !important;
}

.chat-header.has-message .message-square,
.chat-floating-button.has-message .bell {
  color: #0075ff;
}

.chat-floating-button.has-message .bell:before {
  content: "\e9f1" !important;
}

.chat-header.has-message .title,
.chat-floating-button.has-message .title {
  color: #151c29;
  font-weight: 500;
}

/* <span class="tracked-items-badge">5</span> */
.tracked-items-badge {
  background: #BD1105;
  color: #fff;
  font-size: 10px;
  font-weight: 600;
  padding: 1px 5px;
  padding-bottom: 0;
  border-radius: 20px;
  margin-right: -5px;
  margin-top: -10px;
}

.chat-window-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  pointer-events: none;
}

.chat-window-wrapper * {
  pointer-events: all;
}

.chat-window {
  background: #fff;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid #e5e8ef;
  border-radius: 3px 3px 0 0;
  position: relative;
}

.chat-window .loading {
  position: absolute;
  background: #fff;
}

.loading-conversations {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -90px;
  margin-top: 45px;
}

.chat-window .col-sm-8 {
  padding-left: 10px;
}

.border-bottom {
  border-bottom: 1px solid #e5e8ef;
}

.border-right {
  border-right: 1px solid #e5e8ef;
}

.border-top {
  border-top: 1px solid #e5e8ef;
}

.border-left {
  border-left: 1px solid #e5e8ef;
}

.chat-header {
  padding: 10px;
}

.icon-new-conversation {
  color: #0075ff;
  font-size: 22px;
  padding: 10px 5px;
  margin-right: -10px;
  cursor: pointer;
  right: 12px;
  position: absolute;
}

.icon-new-conversation:hover {
  color: #0073e1;
}

.chat-info-settings-close-wrapper {
  display: table;
  width: 100%;
}

.chat-info-settings-close-wrapper * {
  vertical-align: middle;
}

.chat-info,
.chat-settings-close {
  display: table-cell;
}

.chat-info {
  padding: 5px 0;
  vertical-align: top;
  height: 50px;
}

.chat-settings-close {
  width: 80px;
  padding-left: 15px;
}

.chat-info-title .title {
  color: #151c29;
  font-weight: 500;
  display: inline-block;
}

.chat-info-title .client {
  color: #9ba3b0;
  font-style: italic;
  display: inline-block;
  margin-left: 5px;
}

.chat-info-participants {
  white-space: nowrap;
  position: absolute;
  left: 15px;
  right: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chat-info-participants .user {
  display: inline-block;
}

.notifications-disabled {
  opacity: 0.5;
  cursor: default;
}

.notifications-disabled .bell-off {
  color: #BD1105;
}

.chat-settings-close i {
  color: #b3bac5;
  cursor: pointer;
}

.chat-settings-close i:hover {
  color: #485364;
}

.chat-settings-close .search {
  font-size: 1.15rem;
  vertical-align: 1px;
}

.chat-settings-close .settings {
  font-size: 1.15rem;
  /* float: right; */
  margin-right: 15px;
  position: absolute;
  right: 45px;
  top: 12px;
}

.chat-settings-close .x {
  margin-left: 10px;
  font-size: 22px;
  vertical-align: 0px;
}

.conversations-list-wrapper {
  padding: 0 5px 0 10px;
  /* margin-right: -5px; */
}

.search-conversations {
  position: relative;
  margin-right: -10px;
  display: none;
}

.search-conversations:before {
  font-family: "feather" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: -2px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e9aa";
  position: absolute;
  top: 7px;
  left: 10px;
  color: #b3bac5;
  pointer-events: none;
}

.search-conversations input {
  padding-left: 30px;
  min-height: 0;
}

.conversations-list {
  margin-top: 10px;
  padding-right: 7px;
  margin-right: -17px;
  /* height: 50vh; */
  /* height: 65.1vh; */
  height: 79vh;
  margin-bottom: 4px;
  overflow: visible;
  overflow-y: auto;
}

.app-chat-wrapper {
  display: flex;
  flex-direction: column;
  height: 85vh;
}

.chat-wrapper {
  padding: 10px 0;
  padding-right: 10px;
  /* height: 49.45vh; */
  flex-grow: 1;
  overflow: visible;
  overflow-y: auto;
  margin-right: -13px;
}

.conversations-list-item {
  border-bottom: 1px solid #f0f1f3 !important;
  display: block;
  padding: 10px;
  padding-bottom: 12px;
  position: relative;
}

.conversations-list-item.archived {
  opacity: 0.5;
}

.chat-archived-alert {
  position: absolute;
  padding: 15px;
  top: 0;
  left: 0;
  right: 0;
  font-size: 13px;
  font-weight: 500;
  background: #e4f1fe;
  color: #151c29;
  z-index: 5;
  border-bottom: 1px solid #e5e8ef;
}

.conversations-list-item:hover,
.conversations-list-item:focus {
  background: #f4f4f6;
  border-radius: 2px;
}

.conversations-list-item .message-date {
  color: #8a8f98;
  white-space: nowrap;
  padding-left: 10px;
}

.conversations-list-item.has-message .message-date {
  color: #0075ff;
}

.conversations-list-item:last-child {
  border: none;
}

.flex-title {
  display: flex;
  min-height: 20px;
}

.conversations-list-item .title-wrapper {
  flex-grow: 1;
  width: calc(100% - 200px);
}

.conversations-list-item .title {
  color: #151c29;
  margin-bottom: 5px;
  font-weight: 500;
  position: relative;
}

.conversations-list-item .badge {
}

.conversations-list-wrapper .title-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  width: auto;
  float: left;
}

.conversations-list-wrapper .title-overflow.has-badge {
  max-width: calc(100% - 30px);
}

.conversations-list-item .client {
  color: #485364;
  font-size: 1rem;
  font-style: italic;
  display: inline-block;
  margin-top: -5px;
  vertical-align: top;
}

.conversations-list-item .short-message {
  color: #151c29;
  max-height: 37px;
  margin-bottom: 0;
  position: relative;
  display: block;
  max-width: 100%;
  overflow: hidden;
  font-size: 1rem;
  margin-top: -2px;
  word-break: break-word;
}

.conversations-list-item .short-message:after {
  content: "";
  height: 17px;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 25%;
  background: linear-gradient(
    to right,
    transparent,
    rgba(255, 255, 255, 1) 80%
  );
}

.conversations-list-item.active {
  background: #f4f4f6;
  border-radius: 2px;
  border-left: 3px solid #0075ff;
  border-bottom: none;
  padding-left: 7px;
  padding-bottom: 13px;
}

.conversations-list-item:hover .short-message:after,
.conversations-list-item.active .short-message:after {
  background: linear-gradient(to right, transparent, #f4f4f6 80%);
}

.conversations-list-item.unread .title-wrapper *,
.conversations-list-item.unread .short-message {
  font-weight: 600 !important;
}

.conversations-list-item.unread .title,
.conversations-list-item.unread .message-date {
  color: #0075ff;
}

.date-separator {
  padding: 10px;
  padding-top: 20px;
  padding-bottom: 0;
  text-align: center;
  clear: both;
  position: relative;
  background: #fff;
}

.date-separator:first-child {
  padding: 10px;
  padding-bottom: 0;
}

.date-separator:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
  border-bottom: 1px solid #e5e8ef;
  z-index: 0;
}

.date-separator .date {
  position: relative;
  z-index: 1;
  background: #fff;
  display: inline-block;
  padding: 0 10px;
}

.status-message {
  clear: both;
  padding: 20px 0;
  text-align: center;
}

.is-status-message + .is-status-message .status-message {
  padding-top: 0;
}

.status-message .user {
  color: #151c29;
  font-weight: 500;
}

.status-message a {
  text-decoration: underline !important;
}

.status-message .text {
  font-size: 12px;
  color: #151c29;
}

.message-bubble {
  padding-top: 15px;
  clear: both;
  position: relative;
}

.message-bubble .s1 sup {
  top: -0.05em;
  font-size: 12px !important;
  line-height: 1 !important;
}

.chat-wrapper-v4 {
  padding-bottom: 15px;
  padding-right: 5px;
}

.chat-wrapper-v4::after {
  content: "";
  display: block;
  clear: both;
}

.message-bubble * {
  vertical-align: top;
  white-space: pre-wrap;
}

.message-bubble .text > a {
  text-decoration: underline !important;
  font-weight: 500 !important;
}

.message-bubble .download-link {
  font-weight: 500;
  background: #cee7ff;
  display: inline-block;
  line-height: 1;
  padding: 3px 5px;
  border-radius: 5px;
  margin-top: -4px;
  vertical-align: 0px;
}

.message-bubble .answer-yes {
  font-weight: 500;
  background: #cef5d6;
  color: #00901f;
  display: inline-block;
  line-height: 1;
  padding: 3px 5px;
  border-radius: 5px;
  margin-top: -4px;
  vertical-align: 0px;
}

.message-bubble .answer-no {
  font-weight: 500;
  background: #fdd3d7;
  color: #c91428;
  display: inline-block;
  line-height: 1;
  padding: 3px 5px;
  border-radius: 5px;
  margin-top: -4px;
  vertical-align: 0px;
}

.message-bubble .answer-not-sure {
  font-weight: 500;
  background: #ffe4cc;
  color: #cb5100;
  display: inline-block;
  line-height: 1;
  padding: 3px 5px;
  border-radius: 5px;
  margin-top: -4px;
  vertical-align: 0px;
}

.message-bubble .avatar {
  /* margin-top: 10px; */
  margin-right: 5px;
}

.message-bubble.me {
  text-align: right;
}

.message-bubble.me .text > a {
  color: #fff !important;
}

.message-bubble.me + .message-bubble.me > .message-bubble-wrapper {
  margin-top: -12px;
  /* margin-right: 40px; */
}

.message-bubble.me .avatar {
  display: none;
}

/* .message-bubble.me + .message-bubble.me .avatar {
    display: none;
} */

.message-bubble.me .avatar {
  margin-right: 0;
  margin-left: 7px;
  float: right;
}

.is-status-message .message-bubble-wrapper {
  background: #eceef0;
  border: 1px solid #e5e8ef;
  width: 100%;
}

.is-status-message .message-bubble-wrapper .message-bubble-title .title,
.is-status-message .message-bubble-wrapper .text {
  color: #151c29;
  flex-grow: 1;
}

.is-status-message .message-bubble-wrapper .message-bubble-title .date {
  color: #485364;
}

.message-bubble.others + .message-bubble.others > .message-bubble-wrapper,
.message-bubble.is-status-message
  + .message-bubble.is-status-message
  .message-bubble-wrapper {
  margin-top: -12px;
  margin-left: 40px;
}

.message-bubble.others + .message-bubble.others .avatar,
.message-bubble.is-status-message + .message-bubble.is-status-message .avatar {
  display: none;
}

.message-bubble-wrapper {
  border-radius: 0 16px 16px 16px;
  padding: 12px 15px;
  background: #f7f8f9;
  display: inline-block;
  text-align: left;
  max-width: 70%;
  position: relative;
  box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.08), 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
}

.message-bubble-wrapper img {
  max-width: 100% !important;
}

.message-actions {
  opacity: 0;
  display: flex;
  position: absolute;
  left: -100%;
  top: 0;
  bottom: 0;
  padding-right: 8px;
  align-items: center;
  width: 100%;
  text-align: right;
  justify-content: flex-end;
  pointer-events: none;
}

.message-actions * {
  pointer-events: all;
}

.message-actions .dropdown-menu {
  min-width: auto;
  right: 0;
  left: auto;
  z-index: 9999;
  width: 124px;
}

.message-actions .dropdown-menu a {
  padding: 3px 5px;
  padding-right: 10px;
  color: #485364 !important;
}

.message-bubble:hover .message-actions,
.message-bubble-wrapper:hover .message-actions,
.message-actions:hover {
  opacity: 1;
}

.message-actions i {
  padding: 5px;
  cursor: pointer;
}

.message-actions i.more-horizontal {
  color: #485364;
}

.others .message-actions {
  left: 98px;
}

.others .message-actions .dropdown-menu {
  right: 0;
  left: auto;
}

.message-bubble-wrapper .btn {
  margin-top: 10px;
}

.message-bubble-wrapper .btn-link {
  margin-top: 50px;
}
.tracked-item .message-bubble-wrapper .message-bubble-title .title,
.tracked-item .message-bubble-wrapper .text {
  color: #151c29;
}

.tracked-item .message-bubble-wrapper .text {
  min-height: 20px;
}

.tracked-item .message-bubble-wrapper .message-bubble-title .date {
  color: #8a8f98;
}

.message-bubble-wrapper .message-bubble-title .title,
.message-bubble-wrapper .message-bubble-title .date {
  font-size: 12px !important;
  max-width: 100% !important;
  overflow: hidden !important;
}

.others > .message-bubble-wrapper {
  background: #fff;
}

.reply-bubble.others > .message-bubble-wrapper .text a {
  color: #0075ff !important;
}

.message-bubble-wrapper + .message-bubble-wrapper {
  margin-left: 41px;
  margin-top: 5px;
  float: left;
  clear: both;
}

.message-bubble.me > .message-bubble-wrapper {
  background: #485363;
  /* float: right; */
  border-radius: 16px 16px 0 16px;
}

.reply-bubble.me > .message-bubble-wrapper {
  background: #485363;
  border-radius: 16px 16px 0 16px;
}

.message-bubble.me > .message-bubble-wrapper .message-bubble-title .title,
.message-bubble.me > .message-bubble-wrapper .text {
  color: #fff;
}

.message-bubble.me > .message-bubble-wrapper .message-bubble-title .title,
.message-bubble.me > .message-bubble-wrapper .text > * {
  color: #fff;
}

.message-bubble.me > .message-bubble-wrapper .message-bubble-title .date {
  color: #d3d9e3;
}

.reply-bubble.me > .message-bubble-wrapper .message-bubble-title .title,
.reply-bubble.me > .message-bubble-wrapper .text,
.reply-bubble.me > .message-bubble-wrapper .text a {
  color: #fff;
}

.reply-bubble.me > .message-bubble-wrapper .message-bubble-title .date {
  color: #d3d9e3;
}

.message-bubble.me .message-bubble-wrapper + .message-bubble-wrapper {
  margin-right: 40px;
  margin-top: 5px;
  float: right;
  clear: both;
}

.message-bubble-title {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.message-bubble-title * {
  vertical-align: middle !important;
}

.tracked-item-from {
  flex-grow: 2;
  position: absolute;
  top: -6px;
  background: #fff;
  padding-right: 5px;
}

.message-bubble-title .title {
  color: #151c29;
  font-weight: 600;
  display: inline-block;
  flex-grow: 1;
  margin-right: 5px;
}

.message-bubble-title .date {
  color: #8a8f98;
  display: inline-block;
}

.tracked-item-more-options {
  z-index: 3;
  position: absolute;
  right: 5px;
  top: 10px;
  background: #ebedf0;
  border-radius: 8px;
  margin-right: 5px;
  cursor: pointer;
  min-width: 32px;
  height: 32px;
  display: inline-block;
  text-align: center;
  line-height: 32px;
  font-size: 1.15rem;
  cursor: pointer;
  display: none;
}

.message-bubble-wrapper:hover .tracked-item-more-options,
.message-bubble-wrapper .more-options.open .tracked-item-more-options {
  display: block;
}

.message-bubble-wrapper .more-options .dropdown-menu {
  right: 10px;
  top: 44px;
}

.message-bubble-wrapper .text {
  margin: 0;
  color: #151c29;
  word-break: break-word;
}

.message-bubble-wrapper .text img {
  width: 300px;
  max-width: 100%;
  border-radius: 5px;
}

.upload-wrapper {
  position: relative;
}

.upload-loading {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  background: rgba(255, 255, 255, 0.9);
  left: -15px;
  top: 1px;
  right: -15px;
  bottom: 0;
  z-index: 2;
}

.chat-input-wrapper .popover .popover-arrow:after {
  border-top-color: #151c29 !important;
}

.is-loading .popover {
  display: none !important;
}

.is-loading .send {
  animation-duration: 0ms !important;
}

.message-download-link {
  text-decoration: none !important;
  display: block;
  margin: 5px 0;
}

.me .message-download-link {
  color: #fff;
}

.conversations-client-widget .upload-loading {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  background: rgba(255, 255, 255, 0.9);
  left: 0;
  top: 1px;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.conversations-client-widget .conversations-sub-tabs {
  top: 95px;
  left: 0;
  right: 0;
}

.upload-loading * {
  display: inline-block;
  margin: auto;
}

.upload-loading img {
  width: 32px;
  margin-bottom: 15px;
}

.file-upload-preview {
  height: 128px;
  width: 128px;
  background: #f7f8f9 url(../../img/ajax-loader.gif) center center no-repeat;
  border-radius: 2px;
  border: 1px solid #e5e8ef;
  margin-top: 10px;
  position: relative;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: -5px;
  border-radius: 8px;
  margin: 10px 0;
  margin-top: 15px;
}

.file-upload-preview.generic {
  background: #f7f8f9 url(../../img/upload-icons/generic.png) center 20px
    no-repeat;
}

.file-upload-preview.csv {
  background: #f7f8f9 url(../../img/upload-icons/csv.png) center 20px no-repeat;
}

.file-upload-preview.doc,
.file-upload-preview.docx {
  background: #f7f8f9 url(../../img/upload-icons/doc.png) center 20px no-repeat;
}

.file-upload-preview.jpg,
.file-upload-preview.png,
.file-upload-preview.gif {
  background-color: #f7f8f9 !important;
  background-position: center center !important;
  background-size: cover !important;
}

.file-upload-preview.pdf {
  background: #f7f8f9 url(../../img/upload-icons/pdf.png) center 20px no-repeat;
}

.file-upload-preview.ppt,
.file-upload-preview.pptx {
  background: #f7f8f9 url(../../img/upload-icons/ppt.png) center 20px no-repeat;
}

.file-upload-preview.xls,
.file-upload-preview.xlsx {
  background: #f7f8f9 url(../../img/upload-icons/xls.png) center 20px no-repeat;
}

.file-upload-preview.zip,
.file-upload-preview.rar {
  background: #f7f8f9 url(../../img/upload-icons/zip.png) center 20px no-repeat;
}

.remove-uploaded-file {
  position: absolute;
  right: -10px;
  top: -10px;
  background: #BD1105;
  color: #fff;
  border: none;
  border-radius: 50px;
  width: 24px;
  height: 24px;
  text-align: left;
  display: inline-block;
  font-size: 12px;
  box-shadow: 0 2px 4px 0 rgba(39, 76, 132, 0.05);
}

.file-upload-preview .file-name {
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  white-space: nowrap;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: none;
}

.file-upload-preview.generic .file-name {
  display: block;
  background: #f7f8f9;
  border-radius: 2px;
  padding: 2px 3px;
}

.remove-uploaded-file i {
  font-size: 1rem;
  position: absolute;
  top: 1px;
  left: 4px;
}

.remove-uploaded-file:hover,
.remove-uploaded-file:focus {
  background: rgb(190, 31, 19);
  color: #fff;
}

.chat-input-wrapper {
  margin-left: -10px;
  margin-right: -10px;
  padding: 10px;
  padding-bottom: 15px;
  padding-top: 0;
  position: relative;
}

.chat-input {
  resize: none;
  min-height: 44px !important;
  max-height: 160px !important;
  line-height: 1.5;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 75px;
  overflow-y: scroll !important;
}

.chat-input-wrapper .paperclip {
  position: absolute;
  right: 50px;
  bottom: 15px;
  font-size: 1.15rem;
  color: #485364;
  padding: 5px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.chat-input-wrapper .paperclip:hover {
  color: #151c29;
}

.chat-input-wrapper .at-sign {
  position: absolute;
  right: 45px;
  bottom: 18px;
  font-size: 22px;
  color: #485364;
  padding: 5px 10px;
  cursor: pointer;
}

.chat-input-wrapper .at-sign:hover {
  color: #151c29;
}

.chat-input-wrapper .send {
  position: absolute;
  right: 0;
  bottom: 15px;
  font-size: 22px;
  color: #0075ff;
  padding: 5px 10px;
  padding-right: 12px;
  cursor: pointer;
}

.chat-input-wrapper .send:hover {
  color: #0073e1;
}

.chat-input-wrapper.no-text .send {
  opacity: 0.3;
  cursor: not-allowed;
}

.chat-user-selection-item {
  margin-bottom: 6px;
  border-radius: 2px;
  padding: 7px;
  cursor: pointer !important;
  border: 1px solid transparent;
}

.chat-user-selection-item * {
  cursor: pointer !important;
}

.chat-user-selection-item:hover {
  background: rgba(39, 76, 132, 0.05);
}

.chat-user-selection-item.selected {
  background: #0075ff;
}

.chat-user-selection-item.selected * {
  color: #fff !important;
}

.chat-user-selection-item-wrapper {
  display: table;
  width: 100%;
}

.chat-user-selection-item-avatar {
  display: table-cell;
  vertical-align: middle;
  width: 42px;
}

.chat-user-selection-item-info {
  display: table-cell;
  vertical-align: middle;
}

.chat-user-selection-item-info .name {
  color: #151c29;
  font-weight: 400;
  margin-bottom: -5px;
}

.chat-user-selection-item.selected .chat-user-selection-item-info .name {
  font-weight: 600;
}

/*  */
/*  */
/* CLIENT WIDGET */
/*  */
/*  */
.conversations-client-widget {
  position: relative;
}

.conversations-client-widget .loading {
  position: absolute;
  background: #fff;
}

.conversations-client-widget .conversations-list {
  margin-right: 0;
  height: auto;
  padding-bottom: 10px;
}

.conversations-client-widget .upload-wrapper {
  padding: 0 25px;
}

.conversations-client-widget .search-conversations {
  margin: 0;
  display: block;
}

.conversations-client-widget .conversations-list-wrapper::-webkit-scrollbar,
textarea::-webkit-scrollbar {
  width: 15px;
  height: 16px;
}

.conversations-client-widget
  .conversations-list-wrapper::-webkit-scrollbar-thumb,
textarea::-webkit-scrollbar-thumb {
  height: 6px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 20px;
  background-color: rgba(127, 142, 164, 0.3);
}

.conversations-client-widget
  .conversations-list-wrapper::-webkit-scrollbar-thumb:hover,
textarea::-webkit-scrollbar-thumb:hover {
  background-color: rgba(127, 142, 164, 0.4);
}

.conversations-client-widget
  .conversations-list-wrapper::-webkit-scrollbar-button,
textarea::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

.conversations-client-widget
  .conversations-list-wrapper::-webkit-scrollbar-corner,
textarea::-webkit-scrollbar-corner {
  background-color: transparent;
}

.conversations-client-widget {
  background-color: #fff;
  border: none;
  border: 1px solid #e5e8ef;
  box-shadow: 0 2px 4px 0 rgba(39, 76, 132, 0.05);
  border-radius: 2px;
  margin-bottom: 15px;
}

.conversations-client-widget .chat-info-settings-close-wrapper {
  padding-left: 45px;
  border-bottom: 1px solid rgba(229, 232, 239, 0.71);
}

.conversations-client-widget .chat-settings-close {
  width: 48px;
  padding-left: 15px;
}

.conversations-client-widget .chat-wrapper {
  padding-left: 10px;
  margin-right: 0;
  flex-grow: 1;
}

.conversations-client-widget .chat-input-wrapper {
  margin: 0;
}

.conversations-client-widget {
  position: relative;
  /* overflow: hidden; */
}

.conversations-client-widget .upload-wrapper {
  margin: 0;
}

.conversations-client-widget .conversations-list-wrapper {
  padding-top: 10px;
  position: absolute;
  left: -100%;
  top: 51px;
  bottom: 0;
  width: 420px;
  background: #fff;
  /* padding-right: 10px; */
  border-right: 1px solid rgba(229, 232, 239, 0.71);
  z-index: 3;
  overflow: visible;
  overflow-y: auto;
  transition: 300ms ease-in-out;
}

.conversations-client-widget
  app-conversations-list.open
  .conversations-list-wrapper {
  left: 0;
}

.conversations-list-backdrop {
  background: rgba(30, 35, 40, 1);
  position: absolute;
  left: 0;
  right: 0;
  top: 51px;
  bottom: 0;
  z-index: 2;
  opacity: 0;
  transition: 300ms ease-in-out;
  visibility: hidden;
}

.conversations-client-widget
  app-conversations-list.open
  + .conversations-list-backdrop {
  opacity: 0.5;
  visibility: visible;
}

.conversations-client-widget .arrow-left {
  font-size: 22px;
  position: relative;
  float: left;
  margin-left: -32px;
  margin-top: 9px;
  cursor: pointer;
  color: #0075ff;
}

.conversations-client-widget .message-square {
  font-size: 22px;
  position: relative;
  float: left;
  margin-left: -32px;
  margin-top: 9px;
  cursor: pointer;
}

.conversations-client-widget .messages-count {
  font-family: "Inter UI", sans-serif;
  font-size: 10px;
  position: absolute;
  font-weight: 600;
  top: 7px;
  left: 4px;
  text-align: center;
  width: 14px;
}

.conversations-client-widget .message-square.has-message {
  color: #0075ff;
}

.conversations-client-widget .conversations-tabs {
  margin-left: 0;
}

.conversations-client-widget .conversations-tabs .conversations-tab {
  flex-grow: 1;
  text-align: center;
}

/* CLIENT TASKS */
/* CLIENT TASKS */

.tracked-items-wrapper {
  height: 74vh;
  overflow: visible;
  overflow-y: auto;
  padding-bottom: 20px;
  padding-left: 15px;
  margin-left: -15px;
  overflow-x: hidden;
}

.tracked-item-mentions {
  background: url(../../img/comments-icon.png) center center no-repeat;
  background-size: cover !important;
  width: 21px;
  height: 21px;
  color: #fff !important;
  text-align: center;
  font-size: 10px;
  font-weight: 500;
  padding-top: 4px;
}

.tracked-item-mentions + .tooltip {
  right: 30px !important;
  left: auto !important;
}

.tracked-item-mentions.no-comments {
  background: url(../../img/comments-icon-outline.png) center center no-repeat !important;
  background-size: cover !important;
  color: #0075ff !important;
}

.tracked-item .message-bubble-wrapper {
  display: block;
  max-width: 100%;
  margin-right: 10px;
  border-radius: 10px;
  background: #fff;
  padding: 16px;
  float: none !important;
  border-radius: 16px;
  border: 1px solid #dbe0ea;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
}

.status-comments {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.client-task-card-footer-right {
  display: flex;
  align-items: center;
  gap: 4px;
}

.client-task-card-footer-right .attachments-counter i {
  vertical-align: -1px;
}

.client-task-card-footer-right .attachments-counter {
  margin-right: 16px;
}

.comments-link {
  font-size: 1rem;
}

.comments-wrapper-wrapper {
  max-height: 1000px;
  transition: max-height 300ms ease-in;
  margin: 0 -10px;
}

.comments-wrapper-wrapper.closed {
  max-height: 0;
  overflow: hidden;
  transition: max-height 300ms ease-out;
}

.comments-wrapper {
  background: rgba(246, 247, 249, 0.5);
  margin-top: 10px;
  padding: 0 15px;
  border-top: 2px solid #ebedf0;
  border-bottom: 2px solid #ebedf0;
}

.tracked-item-comment {
  display: flex;
}

.comments-wrapper .comment-bubble {
  border-bottom: 1px solid #cfd6e0;
  padding: 5px 0;
}

.comments-wrapper .comment-bubble:last-child {
  border-bottom: none;
}

.comments-wrapper * {
  font-size: 12px;
}

.tracked-item-comment .title {
  font-weight: 500;
  color: #151c29;
  margin-right: 6px;
}

.tracked-item-comment .date {
  text-align: right;
}

.comments-input {
  margin: 0;
  padding: 10px 15px;
  padding-bottom: 9px;
  position: relative;
}

.comments-input textarea {
  padding: 6px 9px;
}

.comments-input .send {
  right: 15px;
  top: 11px;
}

.comments-input .chat-input {
  padding-top: 11px !important;
  font-size: 12px;
}

.message-bubble-separator {
  border: none;
  /* border-bottom: 1px solid #DCDCDC; */
  margin-top: 0;
}

.tracked-item.focus .message-bubble-wrapper {
  border: 2px solid #0075ff !important;
  box-shadow: 0 0 0 5px rgba(102, 175, 233, 0.6) !important;
}

.tracked-item.flash .message-bubble-wrapper {
  box-shadow: 0 0 0 5px rgba(102, 175, 233, 0.6) !important;
}

.tracked-item.me > .message-bubble-wrapper {
  /* background: #E6F3FF; */
  border: 2px solid #4da9ff;
}

.tracked-item.created .message-bubble-title {
  position: relative;
}

/* .tracked-item .message-bubble-text {
    margin-top: 20px;
} */

.tracked-item .message-bubble-title {
  min-height: 24px;
}

.tracked-item .message-bubble-title::before {
  color: #151c29;
  font-size: 1rem;
  background: #fff;
  padding: 1px 8px;
  padding-right: 28px;
  border-radius: 50px;
  font-weight: 500;
}

.tracked-item.created .message-bubble-title::before {
  content: "Open";
  color: #BD1105;
  background: #FFE8EA;
  text-transform: uppercase;
}

.tracked-item.waiting .message-bubble-title::before {
  content: "Answered";
  color: #C7700A;
  background: #FFEFD8;
  text-transform: uppercase;
}

.tracked-item.draft .message-bubble-title::before {
  content: "Not Published";
  color: #485364;
  background: #ebedf0;
}

.tracked-item.waiting .message-bubble-wrapper input {
  pointer-events: none;
}

.tracked-item.closed .message-bubble-title::before {
  content: "Closed";
  color: #2F9139;
  background: #D7FFD9;
  text-transform: uppercase;
}

.message-bubble-title .avatar {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 12px;
  margin-left: -22px;
}

.tracked-item .status {
  display: flex;
  align-items: center;
}

.tracked-item .avatar {
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 12px;
}

.tracked-item .avatar img {
  width: 22px;
  height: 22px;
}

/* .tracked-item > .tooltip {
  left: 0 !important;
  right: auto !important;
  transform: translate3d(0, -13px, 0px) !important;
  top: -14px !important;
} */

.in-message-edit {
  background: transparent !important;
  border-color: transparent !important;
  box-shadow: 0 0 0 transparent !important;
  color: #fff !important;
  padding: 0 !important;
}

.tracked-item-action {
  /* display: flex; */
  align-items: center;
  border-top: 1px solid #ebedf0;
  padding-top: 16px;
}

.tracked-item-options {
  flex-grow: 1;
}

.tracked-item-options a {
  font-weight: 500;
  display: flex;
  align-items: center;
}

.tracked-item-options a i {
  margin-right: 8px;
}

.tracked-item-options input[type="radio"] {
  margin: 2px 0 0;
}

.tracked-item-options input[type="checkbox"] {
  margin: 3px 0 0;
}

.tracked-item-options .selected {
  font-weight: 600;
}

.tracked-item-options .not-selected {
  opacity: 0.3;
}

.tracked-item-options label.answer {
  font-weight: 600;
  color: #151c29;
  font-size: 16px !important;
}

.tracked-item-options label {
  font-weight: 400;
  margin: 0;
  margin-right: 10px;
  font-size: 16px !important;
  display: inline-flex;
  align-items: center;
}

.tracked-item-options .disabled {
  opacity: 0.65;
  color: #485364;
  font-weight: 400;
  cursor: default !important;
}

.tracked-item-options .disabled * {
  cursor: default !important;
}

.tracked-item-options .color-red-hover {
  float: right;
  margin-right: -20px;
  position: absolute;
  right: 45px;
  bottom: 10px;
}

.new-open-text ckeditor > .cke_textarea_inline {
  border-radius: 8px;
  border: 2px solid #e3e5e9;
  margin-right: 30px;
}

.go-to-tracked-item-button {
  color: #0075ff;
  border: none;
  background: transparent;
  display: block;
  width: 100%;
  border-top: 1px solid #e5e8ef;
  padding-top: 5px;
  margin-bottom: -4px;
  margin-top: 6px;
}

.go-to-tracked-item-button:hover,
.go-to-tracked-item-button:focus,
.go-to-tracked-item-button:active {
  color: #0073e1;
  text-decoration: underline;
}

.go-to-tracked-item-text {
  color: #0075ff;
  border: none;
  background: transparent;
  /* display: block; */
  /* width: 100%; */
  /* padding-top: 5px;
    margin-bottom: -4px;
    margin-top: 6px; */
}

.go-to-tracked-item-text:hover,
.go-to-tracked-item-text:focus,
.go-to-tracked-item-text:active {
  color: #0073e1;
  text-decoration: underline;
  cursor: pointer;
}

.bot-hidden {
  margin: 0 !important;
  padding: 0 !important;
  height: 0 !important;
  width: 0 !important;
  opacity: 0 !important;
  overflow: hidden !important;
}

.tracked-item-options .file {
  color: #0075ff;
  cursor: pointer;
  text-decoration: none !important;
}

.tracked-item-options .file:hover,
.tracked-item-options .file:focus {
  color: #006acd;
  text-decoration: none !important;
}

.tracked-item-options input[type="file"] {
  position: absolute;
  left: -99999px;
}

.tracked-item .message-bubble-wrapper .answered-at {
  font-size: 12px;
}

.tracked-item.closed > .closed-at {
  color: #2F9139;
  font-size: 12px;
  text-align: right;
  padding-right: 10px;
  margin-top: 3px;
}

.new-tracked-item-wrapper {
  margin-left: -15px;
  padding: 10px 15px;
  position: absolute;
  right: 15px;
  left: 15px;
  bottom: -1px;
}

.new-tracked-item-checkbox label {
  display: block;
  width: 100%;
  padding: 6px 0;
  font-size: 14px !important;
}

.conversations-tabs {
  display: flex;
  margin-left: -15px;
}

.conversations-sub-tabs {
  position: absolute;
  z-index: 2;
  background: #ffffff;
  /* box-shadow: 0 5px 15px 0 rgba(0,0,0,0.1); */
  left: 15px;
  /* border-radius: 5px; */
  /* overflow: hidden; */
  right: 14px;
  top: 44px;
}

.conversations-sub-tabs .conversations-tab {
  padding: 5px 20px !important;
}

.send-reminder-icon {
  float: right;
  font-size: 19px;
  line-height: 11px;
  padding: 5px;
  cursor: pointer;
  margin-right: 5px;
  color: #f0ad4e;
}

.send-reminder-icon:hover {
  color: #ec971f;
}

.btn-reminder-wrapper {
  flex-grow: 1;
  padding-right: 5px;
  padding-right: 25px;
  padding-top: 4px;
}

.btn-remind-all {
  height: 25px;
  padding: 0 7px !important;
}

.conversations-tab {
  padding: 10px;
  cursor: pointer;
}

.conversations-tab:hover {
  color: #151c29;
}

.conversations-tab.active {
  border-bottom: 3px solid #0075ff;
  color: #151c29;
  font-weight: 500;
}

.satisfied-with-answer {
  clear: both;
  text-align: center;
}

.satisfied-with-answer .btn-default {
  margin-right: 10px;
}

.satisfied-with-answer span {
  margin-top: 4px;
  color: #151c29;
  font-weight: 500;
  display: block;
  clear: both;
}

.tracked-items-preview {
  /* background: #fff;
    margin: -15px -15px -15px 0;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 5px;
    height: 410px;
    overflow-y: auto;
    overflow-x: visible; */
}

/* 

.fr-toolbar, .fr-popup {
    border-top: 2px solid #000 !important;
}

.fr-toolbar.fr-inline .fr-arrow, .fr-popup .fr-arrow {
    border-bottom: 5px solid #000 !important;
    top: -7px !important;
}

.fr-toolbar .fr-command.fr-btn, .fr-popup .fr-command.fr-btn {
    width: 24px !important;
    height: 24px !important;
}

.fr-toolbar .fr-command.fr-btn i, .fr-popup .fr-command.fr-btn i {
    font-size: 14px !important;
    width: 14px !important;
    margin: 5px !important;
}

.fr-separator.fr-vs {
    height: 24px !important;
}

.fr-toolbar .fr-command.fr-btn.fr-dropdown::after, .fr-popup .fr-command.fr-btn.fr-dropdown::after {
    display: none !important;
}

 */
:not(.fdb-block) .fr-toolbar,
:not(.fdb-block) .fr-popup {
  z-index: 99999 !important;
}

:not(.fdb-block) .fr-wrapper {
  cursor: text !important;
  color: #151c29;
  background: #fff;
  border: 1px solid #e1e4e9;
  border-radius: 2px;
  box-shadow: inset 0 2px 5px 0 rgba(219, 225, 234, 0.2) !important;
  min-height: 34px;
  margin-right: 10px;
}

:not(.fdb-block) .fr-placeholder {
  color: #b3bac5 !important;
}

:not(.fdb-block) .fr-view p:last-child {
  margin-bottom: 0;
}

:not(.fdb-block) .fr-view.fr-element {
  padding: 6px 9px;
}

.message-bubble-wrapper .fr-view,
.message-bubble-wrapper .fr-view p {
  display: inline;
}

.tracked-item .message-bubble-wrapper .fr-view,
.tracked-item .message-bubble-wrapper .fr-view p {
  display: block;
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.message-bubble-wrapper .fr-view p::before {
  content: " ";
}

.message-bubble-wrapper .fr-view p:first::before {
  content: "";
}

.tracked-items-preview > strong {
  color: #151c29;
}

.tracked-items-preview .message-bubble-wrapper {
  cursor: pointer;
  border: 2px solid transparent;
}

.tracked-items-preview .fr-view {
  border: none;
  min-height: 20px !important;
  box-shadow: none;
  margin: 0;
  padding: 0;
}

.tracked-items-preview .fr-wrapper {
  border: none;
  box-shadow: none !important;
  min-height: 20px !important;
  margin: 0;
  padding: 0;
}

.tracked-item {
  position: relative;
  transition: 300ms;
}

.tracked-item .upload-loading {
  left: 2px;
  top: 17px;
  right: 12px;
  bottom: 2px;
  border-radius: 10px;
}

.new-tracked-item-tail {
  display: none;
  position: absolute;
  right: -17px;
  top: 50%;
  margin-top: -8px;
}

.focus .new-tracked-item-tail {
  display: block;
}

.new-tracked-item-modal-body {
  overflow: auto;
}

.new-tracked-item-modal-body .h4 {
  font-size: 18px !important;
}

.new-tracked-item-modal-body .h5 {
  font-size: 14px !important;
}

.new-tracked-item-modal-body .modal-row {
  display: flex;
  max-height: 69vh;
}

.new-tracked-item-modal-body .left-column {
  border-right: 1px solid #bbc4d4;
  overflow-y: auto;
  overflow-x: hidden;
}

.new-tracked-item-modal-body .request-header {
  position: sticky;
  top: 0;
  z-index: 5;
  height: 45px;
  margin-bottom: -11px;
  background: #f4f5f7;
}

.choose-template {
  position: absolute;
  left: 20px;
}

.choose-template input {
  min-height: auto !important;
  width: 600px;
  max-width: 40vw;
}

.choose-template .dropdown-menu {
  max-height: 159px;
  width: 600px;
  max-width: 40vw;
  overflow-x: hidden;
}

.choose-template-modal.choose-template {
  left: auto;
  right: 10px;
}

.choose-template-modal.choose-template input {
  width: 230px;
}

.choose-template-modal.choose-template .dropdown-menu {
  width: 400px;
  left: -170px !important;
}

.new-tracked-item {
  border: 1px dashed #b3bac5;
  padding: 15px;
  border-radius: 10px;
  color: #0075ff;
  font-weight: 500;
  text-align: center;
  display: block;
  cursor: pointer;
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-weight: 600;
  background: #fff;
  position: sticky;
  bottom: 0;
  z-index: 2;
}

.new-tracked-item:hover {
  border-color: #0075ff;
}

.badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 5px;
  padding-bottom: 2px;
  font-size: 10px;
  margin-top: -15px;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #BD1105;
  border-radius: 10px;
}

.badge.green {
  background-color: #BD1105;
}

.conversations-client-widget .date-separator {
  margin-right: 10px;
}

.conversations-client-widget app-chat {
  display: flex;
  flex-direction: column;
  /* height: 85vh; */
  height: 556px;
}

.conversations-client-widget .tracked-items-wrapper {
  height: 520px !important;
  margin-top: 36px;
  display: none;
}

.conversations-client-widget .tracked-item {
  margin-left: 15px;
}

.conversations-client-widget .files-wrapper {
  padding-left: 15px;
  height: 556px !important;
  display: none;
}

@media screen and (max-width: 1199px) {
  .conversations-client-widget app-chat {
    display: flex;
    flex-direction: column;
    /* height: 85vh; */
    height: 539px;
  }

  .conversations-client-widget .tracked-items-wrapper {
    padding-left: 15px;
    height: 539px !important;
  }

  .conversations-client-widget .files-wrapper {
    padding-left: 15px;
    height: 539px !important;
  }
}

/* CLIENT TASKS */
/* CLIENT TASKS */

/* FILES */
/* FILES */
.files-wrapper {
  height: calc(100vh - 252px);
  overflow: visible;
  overflow-y: auto;
  padding-bottom: 20px;
  padding-left: 5px;
  margin-left: -5px;
}

.files-category:first-child {
  margin-top: 10px;
}

.files-category {
  color: #151c29;
  margin-top: 20px;
  margin-bottom: 10px;
}

.images-gallery {
  margin: 0;
  margin-left: -5px;
  margin-right: 10px;
  margin-bottom: 30px;
}

.files-wrapper .images-gallery div[class^="col"] {
  padding-left: 5px;
  padding-right: 5px;
}

.files-wrapper .images-gallery a {
  margin-bottom: 10px;
  padding-top: 100%;
  display: block;
  background: #f7f8f9;
  background-size: cover !important;
  background-position: center center !important;
  position: relative;
  overflow: hidden;
}

.images-gallery-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.85);
  padding: 5px 5px 2px;
  z-index: 2;
  color: #fff !important;
  opacity: 0;
  bottom: -100%;
  transition: 300ms;
}

.files-wrapper .images-gallery a:hover .images-gallery-caption {
  bottom: 0;
  opacity: 1;
}

.images-gallery-caption .sent_by,
.documents-list-caption .sent_by {
  font-size: 12px;
}

.images-gallery-caption .date,
.documents-list-caption .date {
  font-size: 10px;
  color: #b3bac5;
}

.documents-list-caption .sent_by {
  color: #151c29;
  white-space: nowrap;
}

.files-wrapper .images-gallery a img {
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  height: 100%;
  transform: translateX(-50%);
}

.documents-list a {
  display: flex;
  padding: 5px 0;
  margin-right: 15px;
  align-items: center;
  border-bottom: 1px solid #e5e8ef;
}

.documents-list a:last-child {
  border: none;
}

.documents-list a .filename {
  flex-grow: 2;
  margin-right: 15px;
}

.documents-list a .date {
  color: #b3bac5 !important;
  text-align: right;
}

.show-more {
  text-align: center;
  display: block !important;
  padding: 7px !important;
  border-radius: 2px;
  background: #f7f8f9;
  margin-bottom: 10px;
}

/* FILES */
/* FILES */

/* FILTERS */
.chat-filters {
  height: 50px;
}

.chat-owner {
  color: #151c29;
}

.assignments .feather.user {
  color: #151c29;
  font-size: 1.15rem;
}

.channel-select {
  padding-left: 15px;
  font-weight: 500;
  color: #151c29;
}

.channel-select .feather.chevron-down {
  color: #485364;
}

.channel-select,
.assignments {
  height: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.assignments {
  height: 46px;
}

.chat-filters-right-side {
  display: flex;
  align-items: center;
  height: 50px;
}

.chat-filters-wrapper {
  flex-grow: 1;
}

.chat-filters-items-wrapper {
  display: flex;
  justify-content: center;
}

.chat-filters-item {
  padding: 11px 15px;
  cursor: pointer;
}

.chat-filters-item:hover {
  color: #151c29;
}

.chat-filters-item * {
  vertical-align: middle;
}

.chat-filters-item.all:before {
  content: " ";
  font-size: 17px;
}

.chat-filters-item.open.active {
  color: #151c29;
  border-bottom: 2px solid #0075ff;
}

.chat-filters-item.waiting.active {
  color: #151c29;
  border-bottom: 2px solid #BD1105;
}

.chat-filters-item.to-review.active {
  color: #151c29;
  border-bottom: 2px solid #ff7800;
}

.chat-filters-item.all.active {
  color: #151c29;
  border-bottom: 2px solid #151c29;
}

.chat-filters-item .feather.square {
  color: #0075ff;
  font-size: 1.15rem;
}

.chat-filters-item .feather.clock {
  color: #BD1105;
  font-size: 1.15rem;
}

.chat-filters-item .feather.check-square {
  color: #ff7800;
  font-size: 1.15rem;
}

.chat-filters-right-side .chat-settings-close {
  margin-top: -4px;
}

/* FILTERS */

.visible-640 {
  display: none;
}

.icon-client-list {
  display: none;
}

@media screen and (min-height: 500px) {
  .conversations-list {
    height: 494px;
  }

  .app-chat-wrapper {
    height: 508px;
  }

  /* .tracked-items-wrapper {
        height: 372px
    } */
}

@media screen and (min-height: 700px) {
  .conversations-list {
    height: 594px;
  }

  .app-chat-wrapper {
    height: 608px;
  }

  /* .tracked-items-wrapper {
        height: 472px
    } */
}

@media screen and (min-height: 800px) {
  .conversations-list {
    height: 694px;
  }

  .app-chat-wrapper {
    height: 708px;
  }

  /* .tracked-items-wrapper {
        height: 572px
    } */
}

@media screen and (min-height: 900px) {
  .conversations-list {
    height: 794px;
  }

  .app-chat-wrapper {
    height: 808px;
  }

  /* .tracked-items-wrapper {
        height: 672px
    } */
}

/* RESPONSIVE ACCOUNTANT SIDE */

@media screen and (max-width: 991px) {
  .chat-window-wrapper .container {
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .conversations-list {
    height: calc(100vh - 66px);
  }

  .chat-window-wrapper .app-chat-wrapper {
    height: calc(100vh - 52px);
  }

  .chat-window-wrapper .tracked-items-wrapper {
    height: calc(100vh - 188px);
  }

  .chat-window-wrapper .files-wrapper {
    height: calc(100vh - 154px);
  }

  .col-chat {
    padding-left: 29px;
  }

  .conversations-list-hide {
    position: absolute;
    left: -100%;
    bottom: -1px;
    z-index: 999;
    padding-left: 0;
    background: #fff;
    width: 300px;
    transition: 300ms;
  }

  .col-chat-header {
    position: absolute;
    left: -100%;
    z-index: 999;
    padding-left: 0;
    margin-top: 0px;
    background: #fff;
    width: 300px;
    border-bottom: 1px solid #e5e8ef;
    text-align: center;
    transition: 300ms;
  }

  .conversations-list-hide.open,
  .col-chat-header.open {
    left: -1px;
  }

  .col-chat-header .arrow-left {
    position: absolute;
    left: 2px;
    padding: 1px 10px;
    color: #0075ff;
    font-size: 20px;
    cursor: pointer;
  }

  .icon-client-list {
    display: table-cell;
    vertical-align: middle;
    width: 24px;
    cursor: pointer;
  }

  .icon-client-list img {
    width: 24px;
    margin-left: 13px;
  }

  .chat-info {
    padding-left: 12px;
  }

  .chat-info-participants {
    left: 64px;
  }

  .conversations-list-hide-backdrop {
    background: rgba(30, 35, 40, 1);
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 998;
    opacity: 0;
    transition: 300ms ease-in-out;
    visibility: hidden;
  }

  .conversations-list-hide-backdrop.open {
    opacity: 0.5;
    visibility: visible;
  }
}

@media screen and (max-width: 640px) {
  .col-chat {
    height: calc(100vh - 52px);
  }

  .visible-640 {
    display: block !important;
  }

  .chat-window-wrapper .app-chat-wrapper {
    position: fixed;
    left: 0;
    right: 0;
    top: 95px;
    bottom: 0;
    height: auto;
    padding: 0 15px;
    z-index: 32;
    background: #fff;
  }

  .chat-window-wrapper .conversations-tabs {
    position: fixed;
    left: 16px;
    right: 0;
    background: #fff;
    align-items: center;
    justify-content: center;
  }

  .chat-window-wrapper .conversations-tab {
    flex-grow: 1;
    text-align: center;
  }

  .chat-window-wrapper .conversations-sub-tabs {
    top: 95px;
  }

  .chat-window-wrapper .tracked-items-wrapper {
    height: auto;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 59px;
    padding-left: 30px;
    top: 95px;
    background: #fff;
    z-index: 33;
  }

  .chat-window-wrapper .new-tracked-item-wrapper {
    margin-left: -15px;
    padding: 10px 15px;
    position: fixed;
    right: 0px;
    left: 15px;
    bottom: 0px;
    background: #fff;
    z-index: 33;
  }

  .chat-window-wrapper .files-wrapper {
    left: 0;
    right: 0;
    top: 95px;
    padding-left: 15px;
    bottom: 59px;
    height: auto;
    margin-right: 0;
    margin-left: 0;
    position: fixed;
    background: #fff;
    z-index: 33;
  }

  .conversations-list-hide,
  .col-chat-header {
    width: calc(100% - 50px);
  }
}

@media screen and (max-width: 767px) {
  .chat-floating-button {
    right: 0 !important;
    left: 0;
    /* margin-right: -15px; */
    bottom: 42px;
    border-radius: 5px 5px 0 0 !important;
    width: auto;
    text-align: center;
    border-radius: 0;
    border-width: 1px;
    display: block;
    padding: 8px 40px;
  }

  .clients-list .tabs {
    display: none;
  }

  .mobile-menu-clients .clients-list-wrapper .clients-list {
    padding-bottom: 70px !important;
  }
}

/* v2v2v2v2v */
.list .message-bubble-wrapper {
  border: none !important;
  background: transparent;
  color: #151c29;
  padding: 0;
  margin-top: 10px;
  border-bottom: 1px solid #e5e8ef !important;
  border-radius: 0;
  padding-bottom: 10px;
}

.list .message-bubble:hover .message-bubble-wrapper {
  border: 2px solid #485364 !important;
  border-color: inherit !important;
  background: #f4f5f7;
  padding: 10px;
  border-radius: 10px;
}

/* 
.list .message-bubble-wrapper .message-bubble-title {
    float: right;
}

.list .message-bubble-wrapper .tracked-card-info {
    display: none !important;
}

.list .message-bubble:hover .message-bubble-wrapper .tracked-card-info {
    display: block !important;
    top: 14px;
}

.list .message-bubble-wrapper .tracked-card-info {
    display: none !important;
}
.list .message-bubble:hover .tracked-card-info {
    display: block !important;
} */

.list .message-bubble-separator {
  display: none;
}

.list .message-bubble:hover .message-bubble-separator {
  display: block;
}

.list .message-bubble:hover .message-bubble-wrapper::before {
  display: none;
}

.list .fr-view {
  padding-left: 15px;
}

.list .message-bubble:hover .message-bubble-wrapper .fr-view {
  padding-left: 0;
}

.list .message-bubble {
  padding-top: 0;
}

.list .message-bubble:hover {
  padding-top: 15px;
}

.list .message-bubble-wrapper::before {
  content: "";
  width: 7px;
  display: block;
  height: 7px;
  /* top: 6px; */
  transform: translate3d(0, 6px, 0);
  left: 2px;
  border-radius: 16px;
  position: absolute;
}

.list .created .message-bubble-wrapper::before {
  background: #ff7800 !important;
}

.list .waiting .message-bubble-wrapper::before {
  background: #0075ff !important;
}

.list .closed .message-bubble-wrapper::before {
  background: #2F9139 !important;
}

.list .created.message-bubble:hover .message-bubble-wrapper {
  border-color: #ff7800 !important;
}

.list .waiting.message-bubble:hover .message-bubble-wrapper {
  border-color: #0075ff !important;
}

.list .closed.message-bubble:hover .message-bubble-wrapper {
  border-color: #2F9139 !important;
}

.list .message-bubble-wrapper:hover .message-bubble-separator {
  border-color: inherit;
}

.list .satisfied-with-answer {
  height: 0;
  overflow: hidden;
  visibility: hidden;
  transition: 150ms;
}

.list .message-bubble:hover .satisfied-with-answer {
  height: auto;
  overflow: visible;
  visibility: visible;
}

/* v2v2v2v2v */

@media screen and (max-width: 1199px) {
  .conversations-client-widget app-chat {
    display: flex;
    flex-direction: column;
    /* height: 85vh; */
    height: 539px;
  }

  .conversations-client-widget .tracked-items-wrapper {
    padding-left: 15px;
    height: 539px !important;
  }

  .conversations-client-widget .files-wrapper {
    padding-left: 15px;
    height: 539px !important;
  }
}

@media screen and (min-height: 500px) {
  .conversations-list {
    height: 494px;
  }

  .app-chat-wrapper {
    height: 508px;
  }

  .tracked-items-wrapper {
    height: 372px;
  }
}

@media screen and (min-height: 700px) {
  .conversations-list {
    height: 594px;
  }

  .app-chat-wrapper {
    height: 608px;
  }

  .tracked-items-wrapper {
    height: 472px;
  }
}

@media screen and (min-height: 800px) {
  .conversations-list {
    height: 694px;
  }

  .app-chat-wrapper {
    height: 708px;
  }

  .tracked-items-wrapper {
    height: 572px;
  }
}

@media screen and (min-height: 900px) {
  .conversations-list {
    height: 794px !important;
  }

  .app-chat-wrapper {
    height: 808px;
  }

  .tracked-items-wrapper {
    height: 672px;
  }
}

/* RESPONSIVE ACCOUNTANT SIDE */

@media screen and (max-width: 991px) {
  .chat-window-wrapper .container {
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .conversations-list {
    height: calc(100vh - 66px);
  }

  .chat-window-wrapper .app-chat-wrapper {
    height: calc(100vh - 52px);
  }

  .chat-window-wrapper .tracked-items-wrapper {
    height: calc(100vh - 188px);
  }

  .chat-window-wrapper .files-wrapper {
    height: calc(100vh - 154px);
  }

  .col-chat {
    padding-left: 29px;
  }

  .conversations-list-hide {
    position: absolute;
    left: -100%;
    bottom: -1px;
    z-index: 999;
    padding-left: 0;
    background: #fff;
    width: 300px;
    transition: 300ms;
  }

  .col-chat-header {
    position: absolute;
    left: -100%;
    z-index: 999;
    padding-left: 0;
    margin-top: 0px;
    background: #fff;
    width: 300px;
    border-bottom: 1px solid #e5e8ef;
    text-align: center;
    transition: 300ms;
  }

  .conversations-list-hide.open,
  .col-chat-header.open {
    left: -1px;
  }

  .col-chat-header .arrow-left {
    position: absolute;
    left: 2px;
    padding: 1px 10px;
    color: #0075ff;
    font-size: 20px;
    cursor: pointer;
  }

  .icon-client-list {
    display: table-cell;
    vertical-align: middle;
    width: 24px;
    cursor: pointer;
  }

  .icon-client-list img {
    width: 24px;
    margin-left: 13px;
  }

  .chat-info {
    padding-left: 12px;
  }

  .chat-info-participants {
    left: 64px;
  }

  .conversations-list-hide-backdrop {
    background: rgba(30, 35, 40, 1);
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 998;
    opacity: 0;
    transition: 300ms ease-in-out;
    visibility: hidden;
  }

  .conversations-list-hide-backdrop.open {
    opacity: 0.5;
    visibility: visible;
  }
}

@media screen and (max-width: 640px) {
  .col-chat {
    height: calc(100vh - 52px);
  }

  .visible-640 {
    display: block !important;
  }

  .chat-window-wrapper .app-chat-wrapper {
    position: fixed;
    left: 0;
    right: 0;
    top: 95px;
    bottom: 0;
    height: auto;
    padding: 0 15px;
    z-index: 32;
    background: #fff;
  }

  .chat-window-wrapper .conversations-tabs {
    position: fixed;
    left: 16px;
    right: 0;
    background: #fff;
    align-items: center;
    justify-content: center;
  }

  .chat-window-wrapper .conversations-tab {
    flex-grow: 1;
    text-align: center;
  }

  .chat-window-wrapper .conversations-sub-tabs {
    top: 95px;
  }

  .chat-window-wrapper .tracked-items-wrapper {
    height: auto;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 59px;
    padding-left: 30px;
    top: 95px;
    background: #fff;
    z-index: 33;
  }

  .chat-window-wrapper .new-tracked-item-wrapper {
    margin-left: -15px;
    padding: 10px 15px;
    position: fixed;
    right: 0px;
    left: 15px;
    bottom: 0px;
    background: #fff;
    z-index: 33;
  }

  .chat-window-wrapper .files-wrapper {
    left: 0;
    right: 0;
    top: 95px;
    padding-left: 15px;
    bottom: 59px;
    height: auto;
    margin-right: 0;
    margin-left: 0;
    position: fixed;
    background: #fff;
    z-index: 33;
  }

  .conversations-list-hide,
  .col-chat-header {
    width: calc(100% - 50px);
  }

  .new-tracked-item-modal-body .modal-row {
    display: block;
  }

  .new-tracked-item-modal-body .left-column {
    height: calc(50vh - 119px);
    margin-bottom: 20px;
  }

  .new-tracked-item-modal-body .right-column {
    height: calc(50vh - 119px);
    overflow-y: auto;
    padding-left: 15px;
  }

  .modal-body {
    max-height: calc(100vh - 40px);
  }
}

@media screen and (max-width: 767px) {
  .chat-floating-button {
    right: 0 !important;
    left: 0;
    /* margin-right: -15px; */
    bottom: 42px;
    border-radius: 5px 5px 0 0 !important;
    width: auto;
    text-align: center;
    border-radius: 0;
    border-width: 1px;
    display: block;
    padding: 8px 40px;
  }

  .clients-list .tabs {
    display: none;
  }

  .mobile-menu-clients .clients-list-wrapper .clients-list {
    padding-bottom: 70px !important;
  }
}

/* .chat-window-wrapper .conversations-list {
    margin: 0;
    padding-top: 0 !important;
    padding: 10px 0;
    margin-right: -18px;
    height: 79vh;
    overflow: visible;
    overflow-x: visible;
    overflow-y: auto;
    overflow-y: auto;
    height: calc( 100vh - 117px ) !important;
}

.chat-window-wrapper .new-tracked-item-wrapper {
    margin-left: -15px;
    padding: 10px 15px;
    position: absolute;
    right: 15px;
    left: 15px;
    bottom: 47px;
}

.chat-window-wrapper .tracked-items-wrapper {
    height: calc( 100% - 185px ) !important;
    padding-bottom: 15px;
    bottom: 2px;
} */

.load-messages-wrapper {
  display: block;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 5px;
}

.load-messages {
  display: inline-block;
  margin: auto;
  text-align: center;
  padding: 6px 12px;
  background: #f7f8f9;
  border: 1px solid #e5e8ef;
  border-radius: 10px;
}

.no-hover * {
  cursor: default !important;
}

.no-hover .message-bubble-wrapper {
  opacity: 0.85 !important;
}

.no-hover .tracked-item-mentions {
  display: none !important;
}

/* Pinned messages */
.message-bubble .action {
  position: absolute;
  right: 35px;
  top: 0;
  font-size: 20px;
  padding: 5px;
  background: #fff;
  border-radius: 32px;
  display: none;
  cursor: pointer;
}

.message-bubble .pin {
  position: absolute;
  right: 35px;
  top: 0;
  font-size: 20px;
  padding: 5px;
  background: #fff;
  border-radius: 32px;
  display: none;
  cursor: pointer;
}

.message-bubble .reply {
  right: 5px !important;
}

.message-bubble .create-task {
  right: 65px !important;
}

.message-bubble.me .pin {
  background: #485363;
  color: #fff;
}

.message-bubble.me .action {
  background: #485363;
  color: #fff;
}

.message-bubble.me .pin.create-task {
  right: 35px !important;
}

.message-bubble.pinned .pin {
  color: #cb5100;
}

.message-bubble.completed .pin {
  color: #00e723b9 !important;
}

.message-bubble.me.pinned .pin {
  color: #ff8a6c;
}

.message-bubble:hover .pin {
  display: block;
}

.message-bubble:hover .action {
  display: block;
}

.message-bubble.pinned .pin {
  display: block;
}

.message-bubble .pin.solo {
  right: 5px !important;
}

/* End of pinned messages */

/* Fix expense account dropdown on client task */
.tracked-item .dropdown {
  right: 0;
}

.tracked-item .dropdown-menu {
  max-height: 144px;
  overflow-y: auto;
  overflow-x: hidden;
  right: 20px;
  background: #fff !important;
}

/* Fix expense account dropdown on client task */

.reply-bubble > .message-bubble-wrapper {
  margin-left: 37px;
  opacity: 0.6;
}

.reply-bubble .text,
.reply-bubble .text * {
  font-size: 12px !important;
}

.reply-bubble .title,
.reply-bubble .date {
  font-size: 9px !important;
}

.reply-bubble ~ .message-bubble-wrapper {
  width: 100%;
}
