.dropdown-menu {
	background: #fff !important;
	box-shadow: 0 6px 11px 0 rgba(39,76,132,0.2);
	border-color: #e5e8ef;
	border-radius: 16px;
	padding: 8px;
	padding-left: 10px;
	max-height: 40vh;
	overflow: auto;
}

.dropdown-menu>li>a {
	color: #485364 !important;
	margin-top: 4px !important;
	margin-bottom: 4px !important;
	margin-right: 2px;
	border: 1px solid transparent !important;
	padding: 3px 8px !important;
	font-weight: normal !important;
	font-size: .875rem !important;
	white-space: nowrap;
}

.dropdown-menu>li>a>i {
	margin-right: 4px;
}

.dropdown-menu>li>a:focus, .dropdown-menu>li>a:hover,
.dropdown-menu>.active>a, .dropdown-menu>.active>a:focus, .dropdown-menu>.active>a:hover {
	color: #fff !important;
	text-decoration: none;
	background-color: #EBEDF0 !important;
	color: #151C29 !important;
	border-radius: 8px;
}

.filter .dropdown-menu {
	left: -20px;
}

.dropdown .dropdown-menu {
	/* -webkit-animation-duration: 150ms;
	animation-duration: 150ms;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both; */
}

.no-animation, .no-animation * {
	-webkit-animation-duration: 0ms !important;
	animation-duration: 0ms !important;
}

.dropdown-menu a {
	margin-right: 0 !important;
	display: block;
	width: 100%;
}

.dropdown-menu .divider {
	background-color: #f1f4f7; 
}

.duplicate-job-modal .modal-content,
.duplicate-job-modal .modal-body {
	overflow: visible !important;
}

.duplicate-job-modal .dropdown-menu {
	width: 270px !important;
	max-height: 300px;
}
.duplicate-job-modal .dropdown-menu span {
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
}

.dropdown-header {
	padding: 3px 10px;
}

.dropup .dropdown-toggle::after {
	display: none;
}
.dropdown-toggle::after {
	display: none;
}